/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import A from '@this/shared/atoms/a';
import { ButtonBase, BorderButtonBase, ButtonType } from '@this/shared/atoms/button';
import type { GradeArgs } from '@this/domain/grade';
import type { OrganizationRoleResponse } from '@this/domain/organization_role/organization_role';
import OrganizationRole from '@this/domain/organization_role/organization_role';
import type { Moment } from 'moment';
import type { SettingResponse } from '@this/components/organization/setting/types';
import type { SettingArgs } from '@this/domain/setting';
import type { OrganizationArgs } from '@this/domain/organization/organization2';
import {
  OrganizationBody,
  OrganizationTable,
  OrganizationTd,
  OrganizationTh,
  OrganizationButtonTh,
  OrganizationTitle,
  BulkActionLink,
  BulkDeleteActionLink
} from '@this/components/organization/organization.style';
import type { MembersFilterFormData } from '@this/components/organization/members/types';
import type UserJson from '@this/domain/user/user_json';
import MembersSearchFilter from '@this/components/organization/members/members_search_filter';
import MemberBulkInvitationAndDisabling from '@this/components/organization/members/members_bulk_invitation_and_disabling';
import MemberImportSmartHr from '@this/components/organization/members/members_import_smart_hr';
import Tooltip from '@this/shared/tooltip/tooltip';
import type OrganizationMemberJson from '@this/domain/user/organization_member_json';
import { AccountStatus } from '@this/domain/user/organization_member_json';
import type { WorkflowStyle } from '@this/domain/workflow_style';
import User from '@this/domain/user/user';
import type { BulkActionStatus as BulkActionStatusJson } from '@this/components/organization/types';

import moment from 'moment-timezone';
import DepartmentList from '@this/domain/department/department_list';
import { DeleteMasterButton } from '@this/components/organization/delete-master-button';

import Pagination from '@this/shared/pagination/pagination';
import type { Args } from '@this/domain/organization_base/organization_base';
import OrganizationBaseList from '@this/domain/organization_base/organization_base_list';
import DateSelector from '@this/shared/date_selector/date_selector';
import ArrangementDepartmentSelector from '@this/components/organization/shared/department_selector';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Notification from '../../../notification';
import FlashMessage from '../../shared/flash_message/flash_message';
import Modal from '../../shared/modal/modal';

const BulkActionStatus = require('../../../domain/bulk_action/bulk_action_status');

interface Props {
  serviceId: number;
}

interface State {
  members: User[];
  memberIds: { withApprover: number[]; withoutApprover: number[]; withoutDepartment: number[] };
  departments: any;
  organizationBases: OrganizationBaseList;
  grades: GradeArgs[];
  setting: SettingArgs | null;
  saving: boolean;
  organization: FetchMembersResponse['organization'] | null;
  currentUser: any | null;
  inviteEmail: string;
  filterFormData: MembersFilterFormData;
  inviteOrganizationRole: number;
  organizationRoles: OrganizationRole[];
  inviteErrors: string[] | null;
  inviteSuccessMessage: string | null;
  loading: boolean;
  editingUser: any;
  editingAllApproval: { type: 'domestic' | 'foreign' | null; val: boolean | null };
  bulkInvitation: boolean;
  bulkEnabling: boolean;
  bulkDisabling: boolean;
  importSmartHr: boolean;
  bulkRunning: boolean;
  saveError: string | null;
  saveModalErrors: string[] | null;
  approvalErrors: { domestic: string | null; foreign: string | null };
  currentPage: number;
  totalPage: number;
  expressOutsourcingAvailability: boolean;
  restrictionDistanceAndTimeAvailability: boolean;
  smartHrAuthenticated: boolean;
  importSmartHrMessage: string | null;
  isSmartHrError: boolean;
  isSmartHrExecuting: boolean;
  intervalId: any;
  organizationWorkflowStyle: WorkflowStyle;
  exicPasswordType: string;
}

interface FetchMembersResponse {
  current_user: UserJson;
  members: OrganizationMemberJson[];
  organization: OrganizationArgs & { has_restricted_ip_addresses: boolean };
  total_page: number;
  number_of_searches: number;
  express_outsourcing_availability: boolean;
  restriction_distance_and_time_availability: boolean;
  smart_hr_authenticated: boolean;
  organization_workflow_style: WorkflowStyle;
}

interface BulkActionResponse {
  processed: number;
}

interface OrganizationBaseListResponse {
  organization_bases: Args[];
}

interface SmartHrLinkResponse {
  message: string;
}
type ApprovalApiKey = 'domestic_approval_required' | 'foreign_approval_required';

interface SmartHrExecResponse {
  error_message: any;
  import_action_exists: boolean;
  executing: boolean;
}

class Members extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      members: [],
      memberIds: { withApprover: [], withoutApprover: [], withoutDepartment: [] },
      departments: new DepartmentList([], []),
      organizationBases: new OrganizationBaseList([]),
      grades: [],
      setting: null,
      saving: false,
      organization: null,
      currentUser: null,
      inviteEmail: '',
      inviteOrganizationRole: 0,
      organizationRoles: [],
      filterFormData: {
        text: '',
        accountStatus: AccountStatus.ENABLED,
        pendingAccountStatus: AccountStatus.ENABLED,
        departmentText: '',
        roleId: null,
        gradeId: null,
        sortTypeText: 'CREATED_AT_ASC'
      },
      inviteErrors: null,
      inviteSuccessMessage: null,
      loading: false,
      editingUser: null,
      editingAllApproval: { type: null, val: null },
      bulkInvitation: false,
      bulkEnabling: false,
      bulkDisabling: false,
      importSmartHr: false,
      bulkRunning: false,
      saveError: null,
      saveModalErrors: null,
      approvalErrors: { domestic: null, foreign: null },
      currentPage: 1,
      totalPage: 0,
      expressOutsourcingAvailability: false,
      restrictionDistanceAndTimeAvailability: false,
      smartHrAuthenticated: false,
      importSmartHrMessage: null,
      isSmartHrError: false,
      isSmartHrExecuting: false,
      intervalId: null,
      organizationWorkflowStyle: 'department',
      exicPasswordType: 'password'
    };
    this.handleFlashMessageClose = this.handleFlashMessageClose.bind(this);
    this.handleInvitationSubmit = this.handleInvitationSubmit.bind(this);
    this.handleInviteEmailChange = this.handleInviteEmailChange.bind(this);
    this.handleInviteRoleChange = this.handleInviteRoleChange.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleEditFormSubmit = this.handleEditFormSubmit.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handleOrganizationBaseChange = this.handleOrganizationBaseChange.bind(this);
    this.handleGradeIdChange = this.handleGradeIdChange.bind(this);
    this.handleAddMileageNumber = this.handleAddMileageNumber.bind(this);
    this.handleBirthdayChange = this.handleBirthdayChange.bind(this);
    this.handleRoleIdChange = this.handleRoleIdChange.bind(this);
    this.handleTeikiSectionChange = this.handleTeikiSectionChange.bind(this);
    this.handleAddTeiki = this.handleAddTeiki.bind(this);
    this.handleRemoveTeiki = this.handleRemoveTeiki.bind(this);
    this.fetchMembers = this.fetchMembers.bind(this);
    this.fetchFilteredMembers = this.fetchFilteredMembers.bind(this);
    this.handleShowBulkInvitation = this.handleShowBulkInvitation.bind(this);
    this.handleCloseBulkInvitation = this.handleCloseBulkInvitation.bind(this);
    this.handleShowBulkEnabling = this.handleShowBulkEnabling.bind(this);
    this.handleCloseBulkEnabling = this.handleCloseBulkEnabling.bind(this);
    this.handleShowBulkDisabling = this.handleShowBulkDisabling.bind(this);
    this.handleCloseBulkDisabling = this.handleCloseBulkDisabling.bind(this);
    this.handleShowSmartHr = this.handleShowSmartHr.bind(this);
    this.handleCloseSmartHr = this.handleCloseSmartHr.bind(this);
  }

  componentDidMount(): void {
    this.fetchMembers();
    this.fetchRunningBulkActionStatuses();
    this.fetchOrganizationBases();
    const path = '/organization/departments.json';
    utils.jsonPromise<any>(path).then(result => {
      this.setState({
        departments: new DepartmentList(result.departments, [])
      });
    });
    utils.jsonPromise<SettingResponse>('/organization/setting.json').then(result => {
      this.setState({
        setting: result.setting,
        grades: result.grades
      });
    });
    utils.jsonPromise<OrganizationRoleResponse>('/organization/roles.json').then(result => {
      const roles = result ? result.roles.map(args => new OrganizationRole(args)) : [];
      this.setState({
        organizationRoles: roles,
        inviteOrganizationRole: roles[0].id
      });
    });

    if (this.state.isSmartHrExecuting) {
      this.startInterval();
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.isSmartHrExecuting !== prevState.isSmartHrExecuting) {
      if (this.state.isSmartHrExecuting) {
        this.startInterval();
      } else {
        this.stopInterval();
      }
    }
  }

  componentWillUnmount() {
    this.stopInterval();
  }

  startInterval() {
    const intervalId = setInterval(() => {
      if (this.state.isSmartHrExecuting) {
        this.fetchSmartHrImportAction();
      }
    }, 10000);

    this.setState({ intervalId });
  }

  stopInterval() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
      this.setState({ intervalId: null });
    }
  }

  fetchSmartHrImportAction(): void {
    const params = {};
    utils
      .jsonPromise<SmartHrExecResponse>('/organization/smart_hr/import_actions/latest.json', params)
      .then(result => {
        if (!result.import_action_exists) {
          this.setState({ isSmartHrExecuting: false, importSmartHrMessage: null, isSmartHrError: false });
        } else {
          this.setState({ isSmartHrExecuting: result.executing });
          this.setState({ isSmartHrError: false });
          if (result.executing) {
            this.setState({ importSmartHrMessage: '連携実行中' });
          } else {
            this.setState({ importSmartHrMessage: '連携完了' });
            if (result?.error_message?.message) {
              this.setState({ isSmartHrError: true });
              this.setState({ importSmartHrMessage: result?.error_message?.message });
            }
          }
        }
      })
      .catch(e => {})
      .finally(() => {});
  }

  fetchMembers(page = 1): void {
    this.setState({
      loading: true,
      currentPage: page
    });
    const { filterFormData } = this.state;
    const params = {
      page,
      text: filterFormData.text,
      account_status: filterFormData.accountStatus,
      department_text: filterFormData.departmentText,
      role_id: filterFormData.roleId,
      grade_id: filterFormData.gradeId,
      order_type: filterFormData.sortTypeText
    };
    this.fetchRequestMembers(params);
  }

  fetchFilteredMembers(filterFormData: MembersFilterFormData): void {
    this.setState({
      loading: true,
      filterFormData,
      currentPage: 1
    });
    const params = {
      page: 1,
      text: filterFormData.text,
      account_status: filterFormData.accountStatus,
      department_text: filterFormData.departmentText,
      role_id: filterFormData.roleId,
      grade_id: filterFormData.gradeId,
      order_type: filterFormData.sortTypeText
    };
    this.fetchRequestMembers(params);
  }

  fetchRequestMembers(params: {
    page: number;
    text: MembersFilterFormData['text'];
    account_status: MembersFilterFormData['accountStatus'];
    department_text: MembersFilterFormData['departmentText'];
    role_id: MembersFilterFormData['roleId'];
    grade_id: MembersFilterFormData['gradeId'];
    order_type: MembersFilterFormData['sortTypeText'];
  }) {
    const path = '/organization/members.json';
    utils.jsonPromise<FetchMembersResponse>(path, params).then(result => {
      let inviteEmail;
      if (this.state.inviteEmail && this.state.inviteEmail.length > 0) {
        inviteEmail = this.state.inviteEmail;
      } else if (result.organization.domain) {
        inviteEmail = `@${String(result.organization.domain)}`;
      } else {
        inviteEmail = '';
      }

      const members = _.map(result.members, u => new User(u));

      this.setState({
        members,
        organization: result.organization,
        currentUser: new User(result.current_user),
        inviteEmail,
        loading: false,
        totalPage: result.total_page,
        expressOutsourcingAvailability: result.express_outsourcing_availability,
        restrictionDistanceAndTimeAvailability: result.restriction_distance_and_time_availability,
        smartHrAuthenticated: result.smart_hr_authenticated,
        organizationWorkflowStyle: result.organization_workflow_style
      });
      this.setState({ importSmartHrMessage: null });
    });
  }

  fetchRunningBulkActionStatuses() {
    const path = '/organization/bulk_action_statuses.json';
    const params = { action_type: 'update_members' };
    utils.jsonPromise<{ bulk_action_statuses: BulkActionStatusJson[] }>(path, params).then(result => {
      const statuses = result.bulk_action_statuses.map(status => new BulkActionStatus(status));
      const enqueuedRunning = statuses.filter(status => status.isEnqueued() || status.isRunning());
      this.setState({ bulkRunning: enqueuedRunning.length > 0 });
    });
  }

  fetchOrganizationBases(): void {
    utils.jsonPromise<OrganizationBaseListResponse>('/organization/organization_bases.json').then(result => {
      this.setState({ organizationBases: new OrganizationBaseList(result.organization_bases) });
    });
  }

  handleFilterchange = (formData: MembersFilterFormData) => {
    this.setState({ filterFormData: formData });
  };

  handleEditClick(user: any) {
    this.setState({
      editingUser: _.cloneDeep(user),
      saveModalErrors: null
    });
  }

  handleInviteEmailChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({
      inviteEmail: e.currentTarget.value
    });
  }

  handleInviteRoleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
    e.preventDefault();
    this.setState({
      inviteOrganizationRole: parseInt(e.currentTarget.value, 10)
    });
  }

  sendInvitation(email: string, organizationRoleId: number) {
    const params = {
      user: {
        email,
        organization_role_id: organizationRoleId
      }
    };

    this.setState(
      {
        inviteErrors: null,
        inviteSuccessMessage: null
      },
      () => {
        const path = '/users/invitation.json';
        utils
          .jsonPromise(path, params, 'POST')
          .then(
            () => {
              this.setState({
                inviteEmail: `@${this.state.organization?.domain}`,
                inviteSuccessMessage: `${email}に招待メールを送信しました`
              });
              this.fetchMembers();
            },
            e => {
              const inviteErrors =
                e.status === 400
                  ? utils.dig(e, 'responseJSON', 'errors')
                  : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
              this.setState({
                inviteErrors,
                loading: false
              });
            }
          )
          .catch(e => utils.sendErrorObject(e));
      }
    );
  }

  handleInvitationSubmit(e: React.FormEvent) {
    this.setState({
      loading: true
    });
    e.preventDefault();
    this.sendInvitation(this.state.inviteEmail, this.state.inviteOrganizationRole);
  }

  handleResendInvitationClick(member: any, e: React.MouseEvent) {
    this.setState({
      loading: true
    });
    e.preventDefault();
    this.resendInvitation(member.id);
  }

  resendInvitation(id: number) {
    const params = { id };
    this.setState(
      {
        inviteErrors: null,
        inviteSuccessMessage: null
      },
      () => {
        const path = '/users/invitation/send_invitation.json';
        utils
          .jsonPromise(path, params, 'POST')
          .then(
            () => {
              this.setState({
                inviteEmail: `@${this.state.organization?.domain}`,
                inviteSuccessMessage: '招待メールを送信しました'
              });
              this.fetchMembers();
            },
            e => {
              const inviteErrors =
                e.status === 400
                  ? utils.dig(e, 'responseJSON', 'errors')
                  : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
              this.setState({
                inviteErrors,
                loading: false
              });
            }
          )
          .catch(e => utils.sendErrorObject(e));
      }
    );
  }

  async handleEditFormSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.setState({
      saving: true,
      saveModalErrors: null
    });

    const path = `/organization/members/${this.state.editingUser.id}`;
    return utils
      .jsonPromise(path, this.state.editingUser.updateParams(), 'PUT')
      .then(
        () => {
          this.setState({
            editingUser: null,
            saving: false
          });
          this.fetchMembers();
        },
        error => {
          const state = { saving: false, saveModalErrors: null };

          state.saveModalErrors =
            error.status === 400
              ? utils.dig(error, 'responseJSON', 'errors')
              : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];

          return this.setState(state);
        }
      )
      .catch(e => utils.sendErrorObject(e));
  }

  handleAllApprovalClick(type: 'domestic' | 'foreign', val: boolean, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const withApprover: number[] = [];
    const withoutApprover: number[] = [];
    const withoutDepartment: number[] = [];
    if (type !== null && val === true) {
      this.getFilteredMember().forEach(m => {
        if (!m.department) {
          withoutDepartment.push(m.id);
        } else if (m.department.approvers.length === 0) {
          withoutApprover.push(m.id);
        } else {
          withApprover.push(m.id);
        }
      });
    }
    this.setState({
      memberIds: { withApprover, withoutApprover, withoutDepartment },
      editingAllApproval: { type, val }
    });
  }

  updateAllApprovalConfirmText() {
    const approval = this.state.editingAllApproval;
    if (approval.type === null || approval.val === null) {
      return '';
    }
    const typeTxt = approval.type === 'domestic' ? '国内' : '海外';
    const valTxt = approval.val ? '必要' : '不要';
    return `本当に${typeTxt}承認${valTxt}に一括変更してもよろしいですか？`;
  }

  executeUpdateAllApproval(e: React.MouseEvent) {
    e.preventDefault();
    this.setState({
      saving: true
    });
    const approval = this.state.editingAllApproval;
    const { members } = this.state;
    if (approval.type !== null && approval.val !== null) {
      const userIds: number[] = approval.val
        ? this.state.memberIds.withApprover
        : members.map(m => {
            return m.id;
          });
      let params: { [key in ApprovalApiKey]?: boolean };
      let apiParams: { [key in ApprovalApiKey]?: 1 | 0 };
      if (approval.type === 'domestic') {
        params = { domestic_approval_required: approval.val };
        apiParams = { domestic_approval_required: approval.val ? 1 : 0 };
      } else {
        params = { foreign_approval_required: approval.val };
        apiParams = { foreign_approval_required: approval.val ? 1 : 0 };
      }
      _.extend(
        apiParams,
        _.transform(userIds, (r: any, v: number, k: number) => {
          r[`user_id[${k}]`] = v;
        })
      );
      utils
        .jsonPromise(`/organization/member/approval/${this.state.organization?.id}`, apiParams, 'POST')
        .then(
          () => {
            members.forEach(m => {
              if (userIds.indexOf(m.id) !== -1) {
                _.assign(m, params);
              }
            });

            this.setState({
              saving: false,
              editingAllApproval: { type: null, val: null }
            });
            return this.fetchMembers();
          },
          error => {
            const state = {
              saving: false,
              saveError: '',
              editingAllApproval: { type: null, val: null }
            };
            state.saveError =
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
            return this.setState(state);
          }
        )
        .catch(e => utils.sendErrorObject(e));
    }
  }

  handleAllForeignApprovalClick(param: boolean, e: React.MouseEvent) {
    let approvalApiParam;
    e.preventDefault();
    const user_ids = _.transform(this.getFilteredMember(), (r: any, v: any, k: number) => {
      r[`user_id[${k}]`] = v.id;
    });
    if (param) {
      approvalApiParam = { foreign_approval_required: 1 };
    } else {
      approvalApiParam = { foreign_approval_required: 0 };
    }
    _.extend(approvalApiParam, user_ids);
    this.setState({
      saving: true,
      saveError: null
    });
    this.updateAllApproval(approvalApiParam);
  }

  handleTeikiSectionChange(name: string, e: React.ChangeEvent<HTMLInputElement>) {
    if (name === 'from') {
      this.state.editingUser.teiki.setFrom(e.target.value);
    } else {
      this.state.editingUser.teiki.setTo(e.target.value);
    }
  }

  handleAddTeiki() {
    this.state.editingUser.addTeiki();
  }

  handleRemoveTeiki() {
    this.state.editingUser.removeTeiki();
  }

  async updateAllApproval(approvalApiParam: { [k in ApprovalApiKey]?: number }) {
    return utils
      .jsonPromise(`/organization/member/approval/${this.state.organization?.id}`, approvalApiParam, 'POST')
      .then(
        () => {
          this.setState({
            saving: false
          });
          return this.fetchMembers();
        },
        error => {
          const state = { saving: false, saveError: '' };
          state.saveError =
            error.status === 400
              ? utils.dig(error, 'responseJSON', 'errors')
              : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
          return this.setState(state);
        }
      )
      .catch(e => utils.sendErrorObject(e));
  }

  handleAllApprovalsCancelClick = () => {
    return this.setState({ editingAllApproval: { type: null, val: null } });
  };

  handleCancelClick() {
    return this.setState({ editingUser: null });
  }

  handleFieldChange(name: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    return this.state.editingUser[name](e.currentTarget.value);
  }

  handleApprovalChange(type: 'domestic' | 'foreign', e: React.ChangeEvent<HTMLInputElement>) {
    const department = this.state.editingUser.department;
    const approvers = utils.dig(department, 'approvers') || [];
    const errors = this.state.approvalErrors;
    const organization = this.state.organization;
    const conditions =
      e.currentTarget.value === 'false' ||
      (department && approvers.length > 0) ||
      organization?.workflow_style === 'trip' ||
      (department && department.workflow_style === 'trip') ||
      organization?.freee_workflow;
    if (conditions) {
      const action = type === 'domestic' ? 'setDomesticApprovalRequired' : 'setForeignApprovalRequired';
      errors[type] = null;
      this.state.editingUser[action](e.currentTarget.value);
    } else if (!department) {
      errors[type] = '部署を設定してください。';
    } else if (organization && !organization.freee_workflow)
      errors[type] = `${department.name}に承認者を設定してください`;
    this.setState({ approvalErrors: errors });
  }

  handleBirthdayChange(birthdayMoment: Moment) {
    return this.state.editingUser.setBirthday(birthdayMoment.format('YYYY-MM-DD'));
  }

  handleRoleIdChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    return this.state.editingUser.setOrganizationRoleId(parseInt(e.currentTarget.value, 10));
  }

  handleDepartmentChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const d = this.state.departments.find(e.currentTarget.value);
    return this.state.editingUser.setDepartment(d);
  }

  handleOrganizationBaseChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const ob = this.state.organizationBases.find(Number(e.currentTarget.value));
    return this.state.editingUser.setOrganizationBase(ob);
  }

  handleGradeIdChange(e: React.ChangeEvent<HTMLSelectElement>) {
    return this.state.editingUser.setGradeId(parseInt(e.currentTarget.value, 10));
  }

  handleFlashMessageClose() {
    return this.setState({ saveError: null });
  }

  handleMileageCarrierChange(index: number, e: React.ChangeEvent<HTMLSelectElement>) {
    const editingUser = this.state.editingUser;
    editingUser.mileageNumbers[index].carrier = e.currentTarget.value;
    this.setState({ editingUser });
  }

  handleMileageNumberChange(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    const editingUser = this.state.editingUser;
    editingUser.mileageNumbers[index].number = e.currentTarget.value;
    this.setState({ editingUser });
  }

  handleRemoveMileageNumber(index: number) {
    const editingUser = this.state.editingUser;
    editingUser.mileageNumbers = this.state.editingUser.mileageNumbers.filter((_m: any, i: number) => i !== index);
    this.setState({ editingUser });
  }

  handleAddMileageNumber() {
    this.state.editingUser.mileageNumbers.push({ carrier: 'ANA', number: '' });
    return app.render();
  }

  handleBrowsingDepartmentChange(ids: number[]) {
    const editingUser = this.state.editingUser;
    const changedDepartments = [];
    for (let i = 0; i < ids.length; i += 1) {
      changedDepartments.push({ department_id: ids[i] });
    }
    editingUser.browsingDepartments = changedDepartments;
    this.setState({ editingUser });
  }

  handleShowBulkInvitation() {
    this.setState({ bulkInvitation: true });
  }

  handleCloseBulkInvitation() {
    this.setState({ bulkInvitation: false });
  }

  execBulkInvitation(e: React.MouseEvent) {
    this.setState({ loading: true });
    e.preventDefault();
    const path = '/organization/member/bulk_action/invitation.json';
    const { filterFormData } = this.state;
    const params = {
      text: filterFormData.text,
      account_status: filterFormData.accountStatus,
      department_text: filterFormData.departmentText,
      role_id: filterFormData.roleId,
      grade_id: filterFormData.gradeId
    };
    utils
      .jsonPromise<BulkActionResponse>(path, params, 'POST')
      .then(
        () => {
          this.setState({ bulkInvitation: false, bulkRunning: true });
          alert('一括招待しました。反映には数分〜最大数時間がかかりますので、反映までしばらくお待ちください');
        },
        error => {
          const state = { bulkInvitation: false, saveError: '' };
          state.saveError =
            error.status === 400
              ? utils.dig(error, 'responseJSON', 'errors')
              : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
          return this.setState(state);
        }
      )
      .catch(error => utils.sendErrorObject(error))
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleShowBulkEnabling() {
    this.setState({ bulkEnabling: true });
  }

  handleCloseBulkEnabling() {
    this.setState({ bulkEnabling: false });
  }

  execBulkEnabling(e: React.MouseEvent) {
    e.preventDefault();
    this.setState({
      saving: true,
      bulkEnabling: false
    });
    const path = '/organization/member/bulk_action/enabling.json';
    const { filterFormData } = this.state;
    const params = {
      text: filterFormData.text,
      account_status: filterFormData.accountStatus,
      department_text: filterFormData.departmentText,
      role_id: filterFormData.roleId,
      grade_id: filterFormData.gradeId
    };
    utils.jsonPromise<BulkActionResponse>(path, params, 'POST').then(() => {
      this.setState({ saving: false, bulkRunning: true });
      alert('一括有効化しました。反映には数分〜最大数時間がかかりますので、反映までしばらくお待ちください');
    });
  }

  handleShowBulkDisabling() {
    this.setState({ bulkDisabling: true });
  }

  handleCloseBulkDisabling() {
    this.setState({ bulkDisabling: false });
  }

  execBulkDisabling(e: React.MouseEvent) {
    e.preventDefault();
    this.setState({
      saving: true,
      bulkDisabling: false
    });
    const path = '/organization/member/bulk_action/disabling.json';
    const { filterFormData } = this.state;
    const params = {
      text: filterFormData.text,
      account_status: filterFormData.accountStatus,
      department_text: filterFormData.departmentText,
      role_id: filterFormData.roleId,
      grade_id: filterFormData.gradeId
    };
    utils.jsonPromise<BulkActionResponse>(path, params, 'POST').then(() => {
      this.setState({ saving: false, bulkRunning: true });
      alert('一括無効化しました。反映には数分〜最大数時間がかかりますので、反映までしばらくお待ちください');
    });
  }

  getGradeName(id: number | null) {
    let grade;
    if (id !== null) {
      grade = this.state.grades.find(g => g.id !== undefined && g.id.toString() === id.toString());
    } else {
      grade = this.state.grades.find(g => g.id === undefined);
    }

    // 等級が設定されてない場合、一番最初の等級を表示する
    if (grade) {
      return grade.name;
    }
    return this.state.grades[0] != null ? this.state.grades[0]!.name : undefined;
  }

  deleteMember = (member: User) => {
    this.setState({
      saving: true
    });

    utils
      .jsonPromise(`/organization/members/${member.id}`, {}, 'DELETE')
      .then(() => {
        const index = this.state.members.findIndex(m => m.id === member.id);
        if (index < 0) {
          return;
        }

        const newMembers = [...this.state.members];
        newMembers.splice(index, 1);
        this.setState({ members: newMembers });
      })
      .catch(e => {
        Notification.error(
          e.status === 400
            ? utils.dig(e, 'responseJSON', 'errors')
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        );
        utils.sendErrorObject(e);
      })
      .finally(() => {
        this.setState({
          saving: false
        });
      });
  };

  handleShowSmartHr() {
    this.setState({ importSmartHr: true });
  }

  handleCloseSmartHr() {
    this.setState({ importSmartHr: false });
  }

  execImportSmartHr(e: React.MouseEvent) {
    e.preventDefault();
    this.setState({ importSmartHr: false, isSmartHrError: false, loading: true, saving: true });
    const path = '/organization/smart_hr/imports';
    const params = {};

    utils
      .jsonPromise<SmartHrLinkResponse>(path, params, 'POST')
      .then(() => {
        this.setState({ isSmartHrExecuting: true });
        this.setState({ importSmartHrMessage: '連携実行中' });
        alert(
          'SmartHR連携を実行しました。反映には数分〜最大数時間がかかりますので、反映までしばらくお待ちください'
        );
      })
      .catch(e => {
        Notification.error(
          e.status === 400 && e.responseJSON?.errors?.message
            ? e.responseJSON.errors.message
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        );
      })
      .finally(() => {
        this.setState({ loading: false, saving: false });
      });
  }

  private getFilteredMember(): User[] {
    const { text, accountStatus } = this.state.filterFormData;
    return this.state.members.reduce((acc: User[], u: User) => {
      if (u.account_status === accountStatus && Members.isMatchMemberByText(u, text)) {
        acc.push(u);
      }
      return acc;
    }, []);
  }

  private static isMatchMemberByText(u: User, text?: string): boolean {
    if (!text) {
      return true;
    }

    const values: string[] = [
      u.name,
      u.nameRoman(),
      u.nameKana(),
      u.email,
      (u.employee_number == null ? '' : u.employee_number).toString(),
      u.departmentName() || ''
    ];
    return values.some(v => v.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) >= 0);
  }

  getOrganizationRoleName(organization_role_id: number) {
    const result = this.state.organizationRoles.find(item => {
      return item.id === organization_role_id;
    });
    return result ? result.name : '';
  }

  getBrowsingDepartmentIds(editingUser: User): number[] {
    const result = editingUser.browsingDepartments.map(department => department.department_id);
    return result || [];
  }

  handleExicPasswordType = () => {
    if (this.state.exicPasswordType === 'password') {
      this.setState({ exicPasswordType: 'text' });
      return;
    }
    this.setState({ exicPasswordType: 'password' });
  };

  render() {
    const {
      organization,
      memberIds,
      inviteEmail,
      filterFormData,
      editingUser,
      currentUser,
      editingAllApproval,
      organizationRoles,
      currentPage,
      totalPage,
      members,
      bulkRunning,
      loading,
      setting,
      expressOutsourcingAvailability,
      restrictionDistanceAndTimeAvailability,
      smartHrAuthenticated,
      importSmartHrMessage,
      isSmartHrError,
      isSmartHrExecuting,
      organizationBases,
      exicPasswordType
    } = this.state;
    const { serviceId } = this.props;

    return (
      <>
        <FlashMessage
          message={this.state.saveError}
          type="error"
          handleFlashMessageClose={this.handleFlashMessageClose}
        />
        <OrganizationTitle>社員マスタ</OrganizationTitle>
        <OrganizationBody>
          <OrganizationMembersInviteArea>
            <OrganizationMembersInviteForm onSubmit={this.handleInvitationSubmit}>
              <OrganizationMembersInviteLabel>招待するメールアドレス</OrganizationMembersInviteLabel>
              <OrganizationMembersInviteField
                type="text"
                name="inviteEmail"
                value={inviteEmail}
                placeholder="user@example.jp"
                onChange={this.handleInviteEmailChange}
              />

              <OrganizationMembersInviteSelect onChange={this.handleInviteRoleChange}>
                {organizationRoles.map(role => {
                  return (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  );
                })}
              </OrganizationMembersInviteSelect>
              <OrganizationMembersInviteButtonWrap>
                {!loading && <OrganizationMembersInviteButton type="submit" value="招待" />}
              </OrganizationMembersInviteButtonWrap>
            </OrganizationMembersInviteForm>
            <OrganizationMembersCsvLinkArea>
              <span>または</span>
              <BulkActionLink to="/organization/member/csv/bulk_upsert">一括登録・更新</BulkActionLink>
            </OrganizationMembersCsvLinkArea>
            <BulkDeleteActionLink to="/organization/member/csv/bulk_delete/new">一括削除</BulkDeleteActionLink>
          </OrganizationMembersInviteArea>
          {this.state.inviteSuccessMessage && (
            <InviteSuccessMessage>{this.state.inviteSuccessMessage}</InviteSuccessMessage>
          )}
          <OrganizationMembersInviteErrors>
            {this.state.inviteErrors &&
              this.state.inviteErrors.map((e, i) => (
                <div className="red" key={i}>
                  {e}
                </div>
              ))}
          </OrganizationMembersInviteErrors>

          <MembersSearchFilter
            formData={filterFormData}
            onChange={this.handleFilterchange}
            onSearch={this.fetchFilteredMembers}
            departments={this.state.departments}
            organizationRoles={this.state.organizationRoles}
            grades={this.state.grades}
          />

          <MemberEditFlex>
            {utils.isAiTravel(serviceId) && smartHrAuthenticated ? (
              <MemberImportSmartHr
                onShowBulkImportSmartHr={this.handleShowSmartHr}
                importSmartHrMessage={importSmartHrMessage}
                isSmartHrError={isSmartHrError}
                disabled={isSmartHrExecuting}
              />
            ) : (
              <div />
            )}
            <MemberBulkInvitationAndDisabling
              onShowBulkInvitation={this.handleShowBulkInvitation}
              onShowBulkEnabling={this.handleShowBulkEnabling}
              onShowBulkDisabling={this.handleShowBulkDisabling}
              accountStatus={filterFormData.accountStatus}
              currentUserIsAdmin={false}
              disabled={bulkRunning}
            />
          </MemberEditFlex>

          <OrganizationMemberTable>
            <tbody>
              <tr>
                <OrganizationTh>社員番号</OrganizationTh>
                <OrganizationTh>氏名(姓 名)</OrganizationTh>
                <OrganizationTh>氏名カナ(姓 名)</OrganizationTh>
                <OrganizationTh>氏名ローマ字(LastName FirstName)</OrganizationTh>
                {restrictionDistanceAndTimeAvailability && <OrganizationTh>拠点</OrganizationTh>}
                <OrganizationTh>性別</OrganizationTh>
                <OrganizationTh>誕生日</OrganizationTh>
                <OrganizationTh>メール</OrganizationTh>
                <OrganizationTh>電話</OrganizationTh>
                <OrganizationTh>役職等級</OrganizationTh>
                <OrganizationTh>
                  国内承認
                  <div>
                    <AllApprovalUpdateButton
                      className="organization__small_button"
                      buttonType={ButtonType.SUB}
                      onClick={e => this.handleAllApprovalClick('domestic', true, e)}
                    >
                      全員 ✓
                    </AllApprovalUpdateButton>
                    <AllApprovalUpdateButton
                      className="organization__small_button"
                      buttonType={ButtonType.SUB}
                      onClick={e => this.handleAllApprovalClick('domestic', false, e)}
                    >
                      全員 -
                    </AllApprovalUpdateButton>
                  </div>
                </OrganizationTh>
                <OrganizationTh>
                  海外承認
                  <div>
                    <AllApprovalUpdateButton
                      className="organization__small_button"
                      buttonType={ButtonType.SUB}
                      onClick={e => this.handleAllApprovalClick('foreign', true, e)}
                    >
                      全員 ✓
                    </AllApprovalUpdateButton>
                    <AllApprovalUpdateButton
                      className="organization__small_button"
                      buttonType={ButtonType.SUB}
                      onClick={e => this.handleAllApprovalClick('foreign', false, e)}
                    >
                      全員 -
                    </AllApprovalUpdateButton>
                  </div>
                </OrganizationTh>
                <OrganizationTh>部署</OrganizationTh>
                <OrganizationTh>役割</OrganizationTh>
                <OrganizationTh>他ユーザー表示権限</OrganizationTh>
                <OrganizationTh>国内規程額超過ホテル表示権限</OrganizationTh>
                <OrganizationTh>海外規程額超過ホテル表示権限</OrganizationTh>
                {this.state.organization && this.state.organization.sso_enabled && (
                  <OrganizationTh>ログイン方法</OrganizationTh>
                )}
                <OrganizationTh>招待</OrganizationTh>
                <OrganizationButtonTh>編集</OrganizationButtonTh>
                <OrganizationButtonTh>削除</OrganizationButtonTh>
              </tr>
              {this.state.loading ? (
                <tr>
                  <td colSpan={8}>
                    <img className="select__loading" src="/images/loading.gif" width={50} height={50} />
                  </td>
                </tr>
              ) : (
                <>
                  {members.map((m, i) => {
                    const organizationTdClass = m.disabled ? 'organization__td-disabled' : 'organization__td';
                    let gender = '';
                    if (m.gender === 'male') {
                      gender = '男性';
                    } else if (m.gender === 'female') {
                      gender = '女性';
                    }
                    return (
                      <tr key={i}>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.employee_number}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>{m.name}</OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>{m.nameKana()}</OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.nameRoman()}
                        </OrganizationMemberTd>
                        {restrictionDistanceAndTimeAvailability && (
                          <OrganizationMemberTd className={organizationTdClass}>
                            {m.organizationBaseName()}
                          </OrganizationMemberTd>
                        )}
                        <OrganizationMemberTd className={organizationTdClass}>{gender}</OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>{m.birthday}</OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>{m.email}</OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>{m.tel}</OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {this.getGradeName(m.grade_id)}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.domestic_approval_required ? '必要' : '不要'}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.foreign_approval_required ? '必要' : '不要'}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.departmentName()}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {this.getOrganizationRoleName(m.organizationRoleId)}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.show_other_members ? '◯' : '☓'}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.show_hotel_of_over_limit ? '◯' : '☓'}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.show_foreign_hotel_of_over_limit ? '◯' : '☓'}
                        </OrganizationMemberTd>
                        {this.state.organization && this.state.organization.sso_enabled && (
                          <OrganizationMemberTd className={organizationTdClass}>
                            {m.signInMethod === 'sso' ? 'SSO' : 'パスワード'}
                          </OrganizationMemberTd>
                        )}
                        <OrganizationMemberTd className={organizationTdClass}>
                          {this.state.organization &&
                          this.state.organization.sso_enabled &&
                          m.signInMethod === 'sso' ? (
                            <span>−</span>
                          ) : m.account_status === AccountStatus.ENABLED ||
                            m.account_status === AccountStatus.DISABLED ? (
                            <span>登録済み</span>
                          ) : m.account_status === AccountStatus.INVITED ? (
                            <span>
                              招待中(
                              <A onClick={e => this.handleResendInvitationClick(m, e)}>再送</A>)
                              <InvitedAtTooltipWrapper>
                                <Tooltip>
                                  前回招待日時: {moment(m.invitation_sent_at).format('YYYY/MM/DD HH:mm')}
                                </Tooltip>
                              </InvitedAtTooltipWrapper>
                            </span>
                          ) : m.account_status === AccountStatus.UNINVITE ? (
                            <span>
                              招待前(
                              <a href="#" onClick={e => this.handleResendInvitationClick(m, e)}>
                                招待
                              </a>
                              )
                            </span>
                          ) : (
                            ''
                          )}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {!editingUser && (
                            <OrganizationEditButton
                              type="submit"
                              value="編集"
                              onClick={() => this.handleEditClick(m)}
                            />
                          )}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd>
                          <DeleteMasterButton
                            targetLabel={m.name}
                            onConfirm={() => {
                              this.deleteMember(m);
                            }}
                          />
                        </OrganizationMemberTd>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </OrganizationMemberTable>
          {totalPage > 1 && (
            <Pagination currentPage={currentPage} totalPage={totalPage} handleSearch={this.fetchMembers} />
          )}
          {(editingAllApproval.type !== null || editingAllApproval.val !== null) && (
            <Modal hideModal={this.handleAllApprovalsCancelClick} show title="承認設定の一括編集">
              <AllApprovalUpdate>
                {editingAllApproval.val && memberIds.withApprover.length === 0 ? (
                  <AllApprovalUpdateConfirm>
                    変更が適用される社員がいないため承認設定の一括編集は実行できません。
                  </AllApprovalUpdateConfirm>
                ) : (
                  <AllApprovalUpdateConfirm>{this.updateAllApprovalConfirmText()}</AllApprovalUpdateConfirm>
                )}
                {editingAllApproval.val && (
                  <>
                    <AllApprovalUpdateConfirm>変更範囲は以下となります。</AllApprovalUpdateConfirm>
                    <AllApprovalUpdateConfirm>
                      <p>
                        {`
                          ・承認必要に変更可（すでに必要と設定済の社員含む）：
                          ${memberIds.withApprover.length}人
                        `}
                      </p>
                      <p>
                        {`
                          ・承認設定変更不可（部署が未設定）：
                          ${memberIds.withoutDepartment.length}人
                        `}
                      </p>
                      <p>
                        {`
                          ・承認設定変更不可（部署の承認者が未設定）：
                          ${memberIds.withoutApprover.length}人
                        `}
                      </p>
                    </AllApprovalUpdateConfirm>
                  </>
                )}
              </AllApprovalUpdate>
              <MemberEditModalButton>
                <EditModalCancelButtonButton
                  disabled={this.state.saving}
                  onClick={() => this.handleAllApprovalsCancelClick()}
                >
                  キャンセル
                </EditModalCancelButtonButton>
                {(!editingAllApproval.val || memberIds.withApprover.length > 0) && (
                  <EditModalSubmitButtonButton
                    disabled={this.state.saving}
                    onClick={e => this.executeUpdateAllApproval(e)}
                  >
                    はい
                  </EditModalSubmitButtonButton>
                )}
              </MemberEditModalButton>
            </Modal>
          )}
          {this.state.bulkInvitation && (
            <Modal hideModal={this.handleCloseBulkInvitation} show title="表示中のメンバーの一括招待">
              フィルタリング後の対象に対して一括して招待メールを送信します。よろしいですか？
              <MemberEditModalButton>
                <EditModalCancelButtonButton onClick={() => this.handleCloseBulkInvitation()}>
                  キャンセル
                </EditModalCancelButtonButton>
                <EditModalSubmitButtonButton
                  onClick={e => this.execBulkInvitation(e)}
                  disabled={this.state.loading}
                >
                  はい
                </EditModalSubmitButtonButton>
              </MemberEditModalButton>
            </Modal>
          )}
          {this.state.bulkEnabling && (
            <Modal hideModal={this.handleCloseBulkEnabling} show title="表示中のメンバーの一括有効化">
              フィルタリング後の対象に対して一括して有効化します。よろしいですか？
              <MemberEditModalButton>
                <EditModalCancelButtonButton onClick={() => this.handleCloseBulkEnabling()}>
                  キャンセル
                </EditModalCancelButtonButton>
                <EditModalSubmitButtonButton onClick={e => this.execBulkEnabling(e)}>
                  はい
                </EditModalSubmitButtonButton>
              </MemberEditModalButton>
            </Modal>
          )}
          {this.state.bulkDisabling && (
            <Modal hideModal={this.handleCloseBulkDisabling} show title="表示中のメンバーの一括無効化">
              フィルタリング後の対象に対して一括して無効化します。よろしいですか？
              <MemberEditModalButton>
                <EditModalCancelButtonButton onClick={() => this.handleCloseBulkDisabling()}>
                  キャンセル
                </EditModalCancelButtonButton>
                <EditModalSubmitButtonButton onClick={e => this.execBulkDisabling(e)}>
                  はい
                </EditModalSubmitButtonButton>
              </MemberEditModalButton>
            </Modal>
          )}
          {this.state.importSmartHr && (
            <Modal hideModal={this.handleCloseSmartHr} show title="SmartHR連携">
              SmartHRより一括してデータ連携します。よろしいですか？
              <MemberEditModalButton>
                <EditModalCancelButtonButton onClick={() => this.handleCloseSmartHr()}>
                  キャンセル
                </EditModalCancelButtonButton>
                <EditModalSubmitButtonButton onClick={e => this.execImportSmartHr(e)}>
                  はい
                </EditModalSubmitButtonButton>
              </MemberEditModalButton>
            </Modal>
          )}
          {editingUser && (
            <Modal hideModal={this.handleCancelClick} show title="社員情報を変更する">
              <form onSubmit={this.handleEditFormSubmit}>
                <MemberEdit>
                  <MemberEditSection>
                    <MemberEditInputArea>
                      <MemberEditInputLabel htmlFor="employeeNumber">社員番号</MemberEditInputLabel>
                      <input
                        className="member-edit__input"
                        id="employeeNumber"
                        type="text"
                        value={_.isEmpty(editingUser.employee_number) ? '' : editingUser.employee_number}
                        onChange={e => this.handleFieldChange('setEmployeeNumber', e)}
                      />
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel htmlFor="lastName">氏名(姓 名)</MemberEditInputLabel>
                      <input
                        className="member-edit__input"
                        id="lastName"
                        type="text"
                        placeholder="氏"
                        value={_.isEmpty(editingUser.last_name) ? '' : editingUser.last_name}
                        onChange={e => this.handleFieldChange('setLastName', e)}
                      />
                      <input
                        className="member-edit__input"
                        id="firstName"
                        type="text"
                        placeholder="名"
                        value={_.isEmpty(editingUser.first_name) ? '' : editingUser.first_name}
                        onChange={e => this.handleFieldChange('setFirstName', e)}
                      />
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel htmlFor="firstNameKana">氏名カナ(姓 名)</MemberEditInputLabel>
                      <input
                        className="member-edit__input"
                        id="lastNameKana"
                        type="text"
                        placeholder="氏 カナ"
                        value={_.isEmpty(editingUser.last_name_kana) ? '' : editingUser.last_name_kana}
                        onChange={e => this.handleFieldChange('setLastNameKana', e)}
                      />
                      <input
                        className="member-edit__input"
                        id="firstNameKana"
                        type="text"
                        placeholder="名 カナ"
                        value={_.isEmpty(editingUser.first_name_kana) ? '' : editingUser.first_name_kana}
                        onChange={e => this.handleFieldChange('setFirstNameKana', e)}
                      />
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel htmlFor="firstNameRoman">
                        氏名ローマ字(LastName FirstName)
                      </MemberEditInputLabel>
                      <input
                        className="member-edit__input"
                        id="lastNameRoman"
                        type="text"
                        placeholder="Last Name"
                        value={_.isEmpty(editingUser.last_name_roman) ? '' : editingUser.last_name_roman}
                        onChange={e => this.handleFieldChange('setLastNameRoman', e)}
                      />
                      <input
                        className="member-edit__input"
                        id="firstNameRoman"
                        type="text"
                        placeholder="First Name"
                        value={_.isEmpty(editingUser.first_name_roman) ? '' : editingUser.first_name_roman}
                        onChange={e => this.handleFieldChange('setFirstNameRoman', e)}
                      />
                    </MemberEditInputArea>
                    {restrictionDistanceAndTimeAvailability && (
                      <MemberEditInputArea>
                        <MemberEditInputLabel htmlFor="organizationBaseId">拠点</MemberEditInputLabel>
                        <select
                          className="member-edit__select"
                          id="organizationBaseId"
                          value={editingUser.organizationBaseId() || ''}
                          onChange={this.handleOrganizationBaseChange}
                        >
                          <option value="" disabled={false}>
                            -
                          </option>
                          {organizationBases.list.map((ob: any) => (
                            <option value={ob.id} key={ob.id} disabled={false}>
                              {ob.name}
                            </option>
                          ))}
                        </select>
                      </MemberEditInputArea>
                    )}
                    <MemberEditInputArea>
                      <MemberEditInputLabel>性別</MemberEditInputLabel>
                      <Flex>
                        <MemberEditRadioValue>
                          <input
                            type="radio"
                            value="male"
                            checked={editingUser.gender === 'male'}
                            onChange={e => this.handleFieldChange('setGender', e)}
                          />
                          <span>男</span>
                        </MemberEditRadioValue>
                        <MemberEditRadioValue>
                          <input
                            type="radio"
                            value="female"
                            checked={editingUser.gender === 'female'}
                            onChange={e => this.handleFieldChange('setGender', e)}
                          />
                          <span>女</span>
                        </MemberEditRadioValue>
                      </Flex>
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel htmlFor="birthday">誕生日</MemberEditInputLabel>
                      <DateSelector
                        onDateChange={this.handleBirthdayChange}
                        date={editingUser.birthday ? moment(editingUser.birthday) : moment([2000])}
                      />
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel>ログイン方法</MemberEditInputLabel>
                      <Flex>
                        <MemberEditRadioValue>
                          <input
                            type="radio"
                            value="sso"
                            checked={editingUser.signInMethod === 'sso'}
                            onChange={e => this.handleFieldChange('setSignInMethod', e)}
                          />
                          <span>SSO</span>
                        </MemberEditRadioValue>
                        <MemberEditRadioValue>
                          <input
                            type="radio"
                            value="password"
                            checked={editingUser.signInMethod === 'password'}
                            onChange={e => this.handleFieldChange('setSignInMethod', e)}
                          />
                          <span>パスワード</span>
                        </MemberEditRadioValue>
                      </Flex>
                    </MemberEditInputArea>
                    {organization?.has_restricted_ip_addresses && (
                      <MemberEditInputArea>
                        <MemberEditInputLabel>IPアドレス制限対象から除外する</MemberEditInputLabel>
                        <Flex>
                          <MemberEditRadioValue>
                            <input
                              name="ignoreIpAddressRestriction"
                              type="radio"
                              checked={editingUser.ignoreIpAddressRestriction}
                              onChange={() => editingUser.setIgnoreIpAddressRestriction(true)}
                            />
                            <span>除外する</span>
                          </MemberEditRadioValue>
                          <MemberEditRadioValue>
                            <input
                              name="ignoreIpAddressRestriction"
                              type="radio"
                              checked={!editingUser.ignoreIpAddressRestriction}
                              onChange={() => editingUser.setIgnoreIpAddressRestriction(false)}
                            />
                            <span>除外しない</span>
                          </MemberEditRadioValue>
                        </Flex>
                      </MemberEditInputArea>
                    )}
                  </MemberEditSection>
                  <MemberEditSection>
                    <MemberEditInputArea>
                      <MemberEditInputLabel htmlFor="email">メール</MemberEditInputLabel>
                      <input
                        className="member-edit__input"
                        id="email"
                        type="email"
                        value={_.isEmpty(editingUser.email) ? '' : editingUser.email}
                        disabled
                      />
                    </MemberEditInputArea>
                    {setting?.send_user_forwarded_email && (
                      <MemberEditInputArea>
                        <MemberEditInputLabel htmlFor="email">転送アドレス</MemberEditInputLabel>
                        {_.map(editingUser.userForwardedEmails, (e, i) => (
                          <input
                            key={`user_forwarded_email_${i}`}
                            className="member-edit__input"
                            id="user_forwarded_email"
                            type="email"
                            placeholder="user@example.jp"
                            value={e.email}
                            onChange={e => editingUser.setUserForwardedEmails(e.target.value, i)}
                          />
                        ))}
                      </MemberEditInputArea>
                    )}
                    <MemberEditInputArea>
                      <MemberEditInputLabel htmlFor="tel">電話番号</MemberEditInputLabel>
                      <input
                        className="member-edit__input"
                        id="tel"
                        type="tel"
                        value={_.isEmpty(editingUser.tel) ? '' : editingUser.tel}
                        onChange={e => this.handleFieldChange('setTel', e)}
                      />
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel htmlFor="gradeId">役職等級</MemberEditInputLabel>
                      <select
                        className="member-edit__select"
                        id="gradeId"
                        value={editingUser.gradeId || ''}
                        onChange={this.handleGradeIdChange}
                      >
                        {this.state.grades.map(d => (
                          <option value={d.id} key={d.id}>
                            {d.name}
                          </option>
                        ))}
                      </select>
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel>国内承認</MemberEditInputLabel>
                      <Flex>
                        <MemberEditRadioValue>
                          <input
                            id="domesticApprovalRequiredTrue"
                            type="radio"
                            value="true"
                            checked={editingUser.domestic_approval_required}
                            onChange={e => this.handleApprovalChange('domestic', e)}
                          />
                          <span>必要</span>
                        </MemberEditRadioValue>
                        <MemberEditRadioValue>
                          <input
                            id="domesticApprovalRequiredFalse"
                            type="radio"
                            value="false"
                            checked={!editingUser.domestic_approval_required}
                            onChange={e => this.handleApprovalChange('domestic', e)}
                          />
                          <span>不要</span>
                        </MemberEditRadioValue>
                      </Flex>
                      {this.state.approvalErrors.domestic && (
                        <MemberEditModalError>{this.state.approvalErrors.domestic}</MemberEditModalError>
                      )}
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel>海外承認</MemberEditInputLabel>
                      <Flex>
                        <MemberEditRadioValue>
                          <input
                            id="foreignApprovalRequiredTrue"
                            type="radio"
                            value="true"
                            checked={editingUser.foreign_approval_required}
                            onChange={e => this.handleApprovalChange('foreign', e)}
                          />
                          <span>必要</span>
                        </MemberEditRadioValue>
                        <MemberEditRadioValue>
                          <input
                            id="foreignApprovalRequiredFalse"
                            type="radio"
                            value="false"
                            checked={!editingUser.foreign_approval_required}
                            onChange={e => this.handleApprovalChange('foreign', e)}
                          />
                          <span>不要</span>
                        </MemberEditRadioValue>
                      </Flex>
                      {this.state.approvalErrors.foreign && (
                        <MemberEditModalError>{this.state.approvalErrors.foreign}</MemberEditModalError>
                      )}
                    </MemberEditInputArea>
                    <MemberEditModalNote>
                      ※承認機能（国内・海外）を「必要」にするには、承認者設定済の部署を選択してください。
                    </MemberEditModalNote>
                    <MemberEditInputArea>
                      <MemberEditInputLabel htmlFor="departmentId">部署</MemberEditInputLabel>
                      <select
                        className="member-edit__select"
                        id="departmentId"
                        value={editingUser.departmentId() || ''}
                        onChange={this.handleDepartmentChange}
                      >
                        <option
                          value=""
                          disabled={
                            this.state.organizationWorkflowStyle === 'department' &&
                            this.state.editingUser.approvalRequired() &&
                            !this.state.organization?.freee_workflow
                          }
                        >
                          -
                        </option>
                        {this.state.departments.list
                          .filter((d: any) => !d.disabled)
                          .map((d: any) => (
                            <option
                              value={d.id}
                              key={d.id}
                              disabled={
                                this.state.organizationWorkflowStyle === 'department' &&
                                this.state.editingUser.approvalRequired() &&
                                !this.state.organization?.freee_workflow &&
                                d.approvers.length === 0
                              }
                            >
                              {`${d.name}(${d.code})`}
                            </option>
                          ))}
                      </select>
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel>マイレージ</MemberEditInputLabel>
                      {editingUser.mileageNumbers.map(
                        (mileageNumber: { carrier: string; number: string }, index: number) => (
                          <MileageItemsEdit key={index}>
                            <select
                              value={mileageNumber.carrier}
                              onChange={e => this.handleMileageCarrierChange(index, e)}
                            >
                              {['ANA', 'JAL', 'SFJ', 'ADO', 'SNA', 'AXM', 'その他'].map((carrier, i) => (
                                <option key={i}>{carrier}</option>
                              ))}
                            </select>
                            <input
                              className="user-signin__input"
                              id="mileage"
                              type="text"
                              value={mileageNumber.number}
                              onChange={e => this.handleMileageNumberChange(index, e)}
                              autoComplete="new-password"
                            />
                            <MileageItemsRemove onClick={() => this.handleRemoveMileageNumber(index)}>
                              <img src="/images/close_search.png" />
                            </MileageItemsRemove>
                          </MileageItemsEdit>
                        )
                      )}
                      <MileageItemsAdd>
                        <A onClick={() => this.handleAddMileageNumber()}>+マイレージを追加</A>
                      </MileageItemsAdd>
                    </MemberEditInputArea>
                    {organization?.use_exic_expence ||
                      (expressOutsourcingAvailability && (
                        <MemberEditInputArea>
                          <MemberEditInputLabel>EXIC会員ID</MemberEditInputLabel>
                          <input
                            className="member-edit__input"
                            id="exicId"
                            type="text"
                            value={_.isEmpty(editingUser.exicId) ? '' : editingUser.exicId}
                            onChange={e => this.handleFieldChange('setExicId', e)}
                          />
                        </MemberEditInputArea>
                      ))}
                    {organization?.use_exic_expence && (
                      <MemberEditInputArea>
                        <MemberEditInputLabel>EXICパスワード</MemberEditInputLabel>
                        <MemberEditInputAreaFlex>
                          <input
                            className="member-edit__input"
                            id="exicPassword"
                            type={exicPasswordType}
                            value={_.isEmpty(editingUser.exicPassword) ? '' : editingUser.exicPassword}
                            onChange={e => this.handleFieldChange('setExicPassword', e)}
                            autoComplete="new-password"
                          />
                          <span onClick={this.handleExicPasswordType}>
                            {exicPasswordType === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </span>
                        </MemberEditInputAreaFlex>
                      </MemberEditInputArea>
                    )}
                    <MemberEditInputArea>
                      <MemberEditInputLabel htmlFor="roleId">役割</MemberEditInputLabel>
                      <select
                        className="member-edit__select"
                        id="organizationRoleId"
                        value={editingUser.organizationRoleId}
                        onChange={e => this.handleFieldChange('setOrganizationRoleId', e)}
                      >
                        {organizationRoles.map(role => {
                          return (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          );
                        })}
                      </select>
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel>データ閲覧可能範囲</MemberEditInputLabel>
                      <Flex>
                        <MemberEditRadioValue>
                          <input
                            id="browsing-scope-all"
                            type="radio"
                            value="all"
                            checked={editingUser.browsing_scope === 'all'}
                            onChange={e => this.handleFieldChange('setBrowsingScope', e)}
                          />
                          <span>すべて</span>
                        </MemberEditRadioValue>
                        <MemberEditRadioValue>
                          <input
                            id="browsing-scope-department"
                            type="radio"
                            value="department"
                            checked={editingUser.browsing_scope === 'department'}
                            onChange={e => this.handleFieldChange('setBrowsingScope', e)}
                          />
                          <span>自部署のみ</span>
                        </MemberEditRadioValue>
                        <Tooltip>
                          ダッシュボードと出張者情報・旅程一覧で表示する情報を自部署のみに制限することが可能です。
                        </Tooltip>
                        <MemberEditRadioValue>
                          <input
                            id="browsing-scope-multiple"
                            type="radio"
                            value="multiple"
                            checked={editingUser.browsing_scope === 'multiple'}
                            onChange={e => this.handleFieldChange('setBrowsingScope', e)}
                          />
                          <span>任意の複数部署</span>
                        </MemberEditRadioValue>
                      </Flex>
                      {editingUser.browsing_scope === 'multiple' && (
                        <Flex>
                          <ArrangementDepartmentSelector
                            selectedDepartmentIds={this.getBrowsingDepartmentIds(editingUser)}
                            onSelect={ids => this.handleBrowsingDepartmentChange(ids)}
                            filter={false}
                          />
                        </Flex>
                      )}
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel>メール通知設定</MemberEditInputLabel>
                      <Flex>
                        <MemberEditRadioValue>
                          <input
                            id="mailRelated"
                            type="radio"
                            value={0}
                            checked={editingUser.admin_notification === 0}
                            onChange={e => this.handleFieldChange('setAdminNotification', e)}
                          />
                          <span>関連する手配のみ受信する</span>
                        </MemberEditRadioValue>
                        <MemberEditRadioValue>
                          <input
                            id="mailAll"
                            type="radio"
                            value={1}
                            checked={editingUser.admin_notification === 1}
                            onChange={e => this.handleFieldChange('setAdminNotification', e)}
                          />
                          <span>全て受信する</span>
                        </MemberEditRadioValue>
                      </Flex>
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel>他ユーザー表示権限</MemberEditInputLabel>
                      <Flex>
                        <MemberEditRadioValue>
                          <input
                            id="showOtherMembersTrue"
                            type="radio"
                            value="true"
                            checked={editingUser.show_other_members}
                            onChange={e => this.handleFieldChange('setShowOtherMembers', e)}
                          />
                          <span>あり</span>
                        </MemberEditRadioValue>
                        <MemberEditRadioValue>
                          <input
                            id="showOtherMembersFalse"
                            type="radio"
                            value="false"
                            checked={!editingUser.show_other_members}
                            onChange={e => this.handleFieldChange('setShowOtherMembers', e)}
                          />
                          <span>なし</span>
                        </MemberEditRadioValue>
                      </Flex>
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel>国内規程額超過ホテル表示権限</MemberEditInputLabel>
                      <Flex>
                        <MemberEditRadioValue>
                          <input
                            id="showHotelOfOverLimit1"
                            type="radio"
                            value="true"
                            checked={editingUser.show_hotel_of_over_limit}
                            onChange={e => this.handleFieldChange('setShowHotelOfOverLimit', e)}
                          />
                          <span>あり</span>
                        </MemberEditRadioValue>
                        <MemberEditRadioValue>
                          <input
                            id="showHotelOfOverLimit2"
                            type="radio"
                            value="false"
                            checked={!editingUser.show_hotel_of_over_limit}
                            onChange={e => this.handleFieldChange('setShowHotelOfOverLimit', e)}
                          />
                          <span>なし</span>
                        </MemberEditRadioValue>
                      </Flex>
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel>海外規程額超過ホテル表示権限</MemberEditInputLabel>
                      <Flex>
                        <MemberEditRadioValue>
                          <input
                            id="showForeignHotelOfOverLimit1"
                            type="radio"
                            value="true"
                            checked={editingUser.show_foreign_hotel_of_over_limit}
                            onChange={e => this.handleFieldChange('setShowForeignHotelOfOverLimit', e)}
                          />
                          <span>あり</span>
                        </MemberEditRadioValue>
                        <MemberEditRadioValue>
                          <input
                            id="showForeignHotelOfOverLimit2"
                            type="radio"
                            value="false"
                            checked={!editingUser.show_foreign_hotel_of_over_limit}
                            onChange={e => this.handleFieldChange('setShowForeignHotelOfOverLimit', e)}
                          />
                          <span>なし</span>
                        </MemberEditRadioValue>
                      </Flex>
                    </MemberEditInputArea>
                    <MemberEditInputArea>
                      <MemberEditInputLabel>ユーザー状態</MemberEditInputLabel>
                      <Flex>
                        {currentUser.id === editingUser.id ? (
                          <>
                            <MemberEditRadioValue>
                              <input
                                id="userEnabled"
                                type="radio"
                                value="false"
                                checked={!editingUser.disabled}
                                onChange={e => this.handleFieldChange('setDisabled', e)}
                                disabled
                              />
                              <span>有効</span>
                            </MemberEditRadioValue>
                            <MemberEditRadioValue>
                              <input
                                id="userDisabled"
                                type="radio"
                                value="true"
                                checked={editingUser.disabled}
                                onChange={e => this.handleFieldChange('setDisabled', e)}
                                disabled
                              />
                              <span>無効</span>
                            </MemberEditRadioValue>
                          </>
                        ) : (
                          <>
                            <MemberEditRadioValue>
                              <input
                                id="userEnabled"
                                type="radio"
                                value="false"
                                checked={!editingUser.disabled}
                                onChange={e => this.handleFieldChange('setDisabled', e)}
                              />
                              <span>有効</span>
                            </MemberEditRadioValue>
                            <MemberEditRadioValue>
                              <input
                                id="userDisabled"
                                type="radio"
                                value="true"
                                checked={editingUser.disabled}
                                onChange={e => this.handleFieldChange('setDisabled', e)}
                              />
                              <span>無効</span>
                            </MemberEditRadioValue>
                          </>
                        )}
                      </Flex>
                    </MemberEditInputArea>
                  </MemberEditSection>
                </MemberEdit>
                {this.state.saveModalErrors && (
                  <MemberEditModalErrors>
                    {this.state.saveModalErrors.map((error, i) =>
                      typeof error === 'string' ? (
                        <div className="error" key={i}>
                          {error.split('\n').map((line, i) => (
                            <p key={i}>{line}</p>
                          ))}
                        </div>
                      ) : (
                        <div className="error" key={i}>
                          {error[0]}
                        </div>
                      )
                    )}
                  </MemberEditModalErrors>
                )}
                {this.state.saving && (
                  <OrganizationSaving>
                    <img src="/images/loading.gif" width={20} height={20} />
                  </OrganizationSaving>
                )}
                <MemberEditModalButton>
                  <EditModalCancelInputButton
                    type="reset"
                    value="キャンセル"
                    disabled={this.state.saving}
                    onClick={this.handleCancelClick}
                  />
                  <EditModalSubmitInputButton type="submit" value="保存" disabled={this.state.saving} />
                </MemberEditModalButton>
              </form>
            </Modal>
          )}
        </OrganizationBody>
      </>
    );
  }
}

const OrganizationMembersInviteArea = styled.div`
  display: flex;
  align-items: center;
`;

const OrganizationMembersInviteForm = styled.form`
  display: flex;
  align-items: center;
`;

const OrganizationMembersInviteLabel = styled.label`
  font-weight: 400;
`;

const OrganizationMembersInviteField = styled.input`
  && {
    width: 300px;
    margin-left: 10px;
    margin-bottom: 0;
  }
`;

const OrganizationMembersInviteSelect = styled.select`
  margin-left: 10px;
  margin-bottom: 0;
`;

const OrganizationMembersInviteButtonWrap = styled.div`
  margin-left: 10px;
`;

const OrganizationMembersInviteButton = styled.input`
  &&& {
    ${ButtonBase};
    width: 100px;
    padding: 8px 0;
  }
`;

const OrganizationMembersCsvLinkArea = styled.div`
  margin-left: 20px;
  margin-right: auto;
`;

const InviteSuccessMessage = styled.div`
  color: ${props => props.theme.successColor};
`;

const OrganizationMembersInviteErrors = styled.div`
  margin-bottom: 20px;
`;

const AllApprovalUpdateButton = styled.a`
  ${ButtonBase};
  width: 43px;
  margin: 3px 5px 0 0;
  padding: 0.5em;
  letter-spacing: 0;
`;

const OrganizationEditButton = styled.input`
  &&& {
    ${ButtonBase};
    width: 80px;
    margin: 2px auto;
    padding: 5px 0;
  }
`;

const OrganizationMemberTd = styled(OrganizationTd)`
  ${({ className }) =>
    className === 'organization__td-disabled' &&
    `
    background-color: gray;
  `}
`;

const OrganizationMemberTable = styled(OrganizationTable)`
  margin-bottom: 30px;
`;

const AllApprovalUpdate = styled.div``;

const AllApprovalUpdateConfirm = styled.div`
  margin-bottom: 10px;
`;

const InvitedAtTooltipWrapper = styled.div``;

const MemberEdit = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MemberEditSection = styled.div`
  width: 48%;
  padding: 5px;
`;

const MemberEditInputArea = styled.div`
  margin-bottom: 10px;
`;

const MemberEditInputAreaFlex = styled.div`
  display: flex;
  align-items: center;
`;

const MemberEditInputLabel = styled.label`
  font-size: 13px;
  font-weight: normal;
`;

const MemberEditRadioValue = styled.label`
  width: auto;
  > span {
    padding-left: 4px;
  }
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const MemberEditModalNote = styled.div`
  margin-bottom: 20px;
  color: ${props => props.theme.linkColor};
  font-size: 12px;
`;

const Flex = styled.div`
  display: flex;
`;

const MileageItemsEdit = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  select {
    margin-bottom: 0.75em;
  }
`;

const MileageItemsRemove = styled.a`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin: 0 0 0.75rem 8px;
`;

const MileageItemsAdd = styled.div`
  text-align: right;
`;

const MemberEditModalErrors = styled.div`
  margin: 20px;
`;

const MemberEditModalError = styled.p`
  color: ${props => props.theme.redColor};
`;

const MemberEditFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MemberEditModalButton = styled.div`
  display: flex;
  justify-content: flex-end;
  > input,
  > input:hover:hover {
    width: 100px;
    margin-left: 20px;
  }
`;

const EditModalCancelButtonCss = css`
  ${BorderButtonBase};
  width: 100px;
  margin: 0 0 0 20px;
  padding: 0.75em 1em;
`;

const EditModalCancelButtonButton = styled.button`
  ${EditModalCancelButtonCss}
`;

const EditModalCancelInputButton = styled.input`
  && {
    ${EditModalCancelButtonCss}
  }
`;

const EditModalSubmitButtonCss = css`
  ${ButtonBase};
  width: 100px;
  margin: 0 0 0 20px;
  padding: 0.75em 1em;
`;

const EditModalSubmitButtonButton = styled.button`
  ${EditModalSubmitButtonCss}
`;

const EditModalSubmitInputButton = styled.input`
  &&& {
    ${EditModalSubmitButtonCss}
  }
`;

const OrganizationSaving = styled.div`
  width: 110px;
  margin: 0 auto;
`;

export default Members;
